import React from "react";

const Page404 = () => {
  return (
    <div className='w-screen h-screen flex justify-center items-center bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400'>
      <span className='font-carmila text-[130px] text-white'>404</span>
    </div>
  );
};

export default Page404;
