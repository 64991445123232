import React from "react";

const SocialLinks = () => {
  return (
    <div className='flex flex-row gap-2 bg-white/50 p-2 rounded-full'>
      <a
        href='https://t.me/unico_store_uz'
        className='flex bg-white w-[30px] h-[30px] rounded-full cursor-pointer'
      >
        <img
          className='w-full h-full object-cover'
          src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png'
        />
      </a>
      <a
        href='https://www.instagram.com/unico.store.uz'
        className='flex bg-white w-[30px] h-[30px] rounded-full overflow-hidden cursor-pointer'
      >
        <img
          className='w-full h-full object-cover'
          src='https://avatars.mds.yandex.net/i?id=85663518e7abf079801fc3be8cc0be78bdeffd68-4809712-images-thumbs&n=13'
        />
      </a>
    </div>
  );
};

export default SocialLinks;
