import { Route, Routes } from "react-router-dom";
import WanderlustPage from "./pages/Wanderlust/WanderlustPage";
import Page404 from "./pages/404/Page404";

function App() {
  return (
    <Routes>
      <Route path='/wanderlust' element={<WanderlustPage />} />
      <Route path='/*' element={<Page404 />} />
    </Routes>
  );
}

export default App;
