import React from "react";
import logo from "../../assets/unicostore_logo.svg";
import Bracelet from "../../components/Bracelet";
import SocialLinks from "../../components/SocialLinks";

const WanderlustPage = () => {
  return (
    <div className='flex flex-col gap-5 justify-center items-center w-screen h-screen bg-[conic-gradient(at_top,_var(--tw-gradient-stops))] from-purple-500 via-pink-200 to-amber-500'>
      <div className='flex flex-col gap-5 items-center justify-between bg-white w-[90%] md:w-[500px] h-fit sm:h-[90%] md:h-[80%] pt-20 md:pt-8 p-5 rounded-[30px]'>
        <img src={logo} className='w-[130px] h-[130px]' />
        <span className='font-cakelan text-[28px] sm:text-[36px] mt-4 text-purple-800'>
          ✨Wanderlust✨
        </span>
        <span className='font-cakelan text-[18px] sm:text-[22px] mb-6 text-purple-500'>
          to see magic click here👇
        </span>
        <a
          href='https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=c1ee2431bb6a488ab4d8d67bf3612d9f&metadata=01'
          className='cursor-pointer relative bg-gradient-to-r shadow-md from-rose-400 via-fuchsia-500 to-indigo-500 rounded-3xl w-fit h-[90px] py-1 active:scale-90 focus:outline-none focus:shadow-outline transition-transform duration-200'
        >
          <div className='rotate-[20deg] shadow-xl right-[-13px] top-[-13px] flex w-[40px] h-[40px] rounded-full overflow-hidden absolute justify-center items-center'>
            <img
              className=' w-[150%] h-[150%] rounded-full object-cover'
              src='https://www.usatoday.com/gcdn/presto/2019/08/16/USAT/bd6538e4-5535-41ce-857b-518451c3a958-Snapchat_Logo_H.png'
            />
          </div>

          <Bracelet
          //   color='#eca0ff'
          />
        </a>
      </div>
      <SocialLinks />
    </div>
  );
};

export default WanderlustPage;
